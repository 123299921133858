<template>
  <type-page :config="serverList" />
</template>

<script>
import TypePage from '@/components/datainfo/TypePage'

import {
  serverList
} from '@/data_conf/server'

export default {
  components: {
    TypePage
  },
  data () {
    return {
      serverList: serverList({
        openConf: {
          position: 'left',
          isPop: false
        }
      })
    }
  }
}
</script>
